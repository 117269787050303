import * as React from "react";
import { PageProps } from "gatsby";
import ForgotPassword from "../components/user/ForgotPassword";

const ForgotPasswordRoute = (props: PageProps) => {
  return <ForgotPassword />;
};

export const Head = () => (
  <>
    <title>Runic | Forgot Password</title>
  </>
)

export default ForgotPasswordRoute;
