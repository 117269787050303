import React, { useState } from "react";
import {
  CognitoUser,
} from "amazon-cognito-identity-js";
import { getUser } from './CognitoConfig';
import { isValidPassword } from "../../util/validators";
const { default: LogoPath } =
  require("../../images/navigation-icons/popstox.svg") as { default: string };

enum FORM_STATES {
  INIT = 'init',
  CONFIRM_CODE = 'confirm_code',
  COMPLETE = 'complete'
}

const ForgotPassword = () => {

  const [email, setEmail] = useState<string>("")
  const [passwordData, setPasswordData] = useState<{
    user?: CognitoUser;
    confirmCode: string;
    newPassword: string;
    repeatPassword: string;
    newPasswordError: string;
    repeatPasswordError: string;
  }>({
    user: undefined,
    confirmCode: "",
    repeatPassword: "",
    newPasswordError: "",
    repeatPasswordError: "",
    newPassword: ""
  })
  const [formError, setFormError] = useState<null | string>(null)
  const [formState, setFormState] = useState<FORM_STATES>(FORM_STATES.INIT)

  const getFormError = () => {
    if (formError) {
      return (
        <div className={"bg-red-100 border-red-300 text-red-700 p-2 text-sm text-center border rounded-md"}>
          {formError}
        </div>
      )
    } else {
      return null
    }
  }

  const onEmailCode = (e) => {
    e.preventDefault()
    const user = getUser(email)
    user.forgotPassword({
      onSuccess: (data) => {
        setFormError(null)
        setPasswordData({
          ...passwordData,
          user: user
        })
        setFormState(FORM_STATES.CONFIRM_CODE)
      },
      onFailure: (error) => {
        setFormError(error.message)
      }
    })
  }

  const onUpdatePassword = (e) => {
    e.preventDefault()
    const isValidPassword = doValidateForm()
    if (!isValidPassword) {
      setFormError("please fix password errors" );
      return
    }
    const { user, newPassword, confirmCode } = passwordData
    user!.confirmPassword(confirmCode, newPassword, {
      onSuccess() {
        setFormState(FORM_STATES.COMPLETE)
      },
      onFailure(error) {
        setFormError("Failure to set new password, please check your code")
      }
    })
  }

  const onChangeNewPassword = (value) => {
    if (passwordData.newPasswordError && isValidPassword(value)) {
      setPasswordData({
        ...passwordData,
        newPassword: value,
        newPasswordError: ""
      })
    } else {
      setPasswordData({
        ...passwordData,
        newPassword: value,
      })
    }
  }

  const onNewPasswordBlur = () => {
    const { newPassword } = passwordData
    if (!isValidPassword(newPassword)) {
      setPasswordData({
        ...passwordData,
        newPasswordError: "Password invalid, please see requirements",
      })
    } else {
      if (passwordData.newPasswordError) {
        setPasswordData({
          ...passwordData,
          newPasswordError: "",
        })
      }
    }
  }

  const doValidateForm = () => {
    const { newPassword } = passwordData
    let isValid = true
    if (!isValidPassword(newPassword)) {
      setPasswordData({
        ...passwordData,
        newPasswordError: "Password invalid, please see requirements",
      })
      isValid = false
    }
    if (!isRepeatPasswordValid()) {
      setPasswordData({
        ...passwordData,
        repeatPasswordError: "Repeat password must match new password",
      })
      isValid = false
    }
    return isValid
  }

  const isRepeatPasswordValid = () => {
    const { newPassword, repeatPassword } = passwordData
    return newPassword === repeatPassword
  }

  const getRepeatPasswordError = () => {
    if (passwordData.repeatPasswordError) {
      return (<div className={"text-red-700"}>{passwordData.repeatPasswordError}</div>)
    } else {
      return null
    }
  }

  const getPasswordError = () => {
    if (passwordData.newPasswordError) {
      return (<div className={"text-red-700"}>{passwordData.newPasswordError}</div>)
    } else {
      return null
    }
  }

  const onChangeRepeatPassword = (value) => {
    if (passwordData.repeatPasswordError && value === passwordData.newPassword) {
      setPasswordData({
        ...passwordData,
        repeatPassword: value,
        repeatPasswordError: ""
      })
    } else {
      setPasswordData({
        ...passwordData,
        repeatPassword: value,
      })
    }
  }

  const getForm = () => {
    if (formState === FORM_STATES.INIT) {
      return (
        <form className="space-y-6" onSubmit={onEmailCode}>
          <div>
            enter the email used to register your account
          </div>
          <div>
            {getFormError()}
          </div>
          <div>
            <label htmlFor="email" className="block text-sm font-medium text-gray-700">
              Email Address
            </label>
            <div className="mt-1">
              <input
                id="email"
                name="email"
                type="email"
                autoComplete="email"
                onChange={(e) => setEmail(e.target.value)}
                value={email}
                className="block w-full appearance-none rounded-md border border-gray-300 px-3 py-2 shadow-sm focus:border-blue-500 focus:outline-none focus:ring-blue-500 sm:text-sm"
              />
            </div>
          </div>
          <div className={"pt-4"}>
            <button
              type="submit"
              className="flex w-full justify-center rounded-md border border-transparent bg-blue-600 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2"
            >
              Send Code
            </button>
          </div>
        </form>
      )
    } else if (formState === FORM_STATES.CONFIRM_CODE) {
      return (
        <form className="space-y-6" onSubmit={onUpdatePassword}>
          <div>
            enter the verification code sent to your email, and create a new password
          </div>
          <div className={"text-sm"}>
            <b>Requirements:</b> <span className={"italic"}>Passwords must be 8 characters in length, have 1 number, 1 special character, 1 lowercase letter, and 1 uppercase letter.</span>
          </div>
          <div>
            {getFormError()}
          </div>
          <div>
            <label htmlFor="email" className="block text-sm font-medium text-gray-700">
              Email Address
            </label>
            <div className="mt-1">
              <input
                id="email"
                name="email"
                type="email"
                autoComplete="email"
                disabled={true}
                value={email}
                className="block w-full appearance-none rounded-md border border-gray-300 px-3 py-2 text-gray-400 bg-gray-100 shadow-sm focus:border-blue-500 focus:outline-none focus:ring-blue-500 sm:text-sm"
              />
            </div>
          </div>
          <div>
            <label htmlFor="confirm-code" className="block text-sm font-medium text-gray-700">
              Verification Code
            </label>
            <div className="mt-1">
              <input
                id="confirm-code"
                name="confirm-code"
                type="text"
                value={passwordData.confirmCode || ""}
                onChange={(e) => setPasswordData({...passwordData, confirmCode: e.target.value})}
                required
                className="block w-full appearance-none rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 shadow-sm focus:border-blue-500 focus:outline-none focus:ring-blue-500 sm:text-sm"
              />
            </div>
          </div>
          <div>
            <label htmlFor="password" className="block text-sm font-medium text-gray-700">
              New Password
            </label>
            <div className="mt-1">
              <input
                id="password"
                name="password"
                type="password"
                value={passwordData.newPassword || ""}
                onChange={(e) => onChangeNewPassword(e.target.value)}
                onBlur={(e) => { onNewPasswordBlur() }}
                required
                className="block w-full appearance-none rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 shadow-sm focus:border-blue-500 focus:outline-none focus:ring-blue-500 sm:text-sm"
              />
            </div>
            <div>
              {getPasswordError()}
            </div>
          </div>
          <div>
            <label htmlFor="repeat-password" className="block text-sm font-medium text-gray-700">
              Repeat Password
            </label>
            <div className="mt-1">
              <input
                id="repeat-password"
                name="repeat-password"
                type="password"
                value={passwordData.repeatPassword || ""}
                onChange={(e) => onChangeRepeatPassword(e.target.value)}
                required
                className="block w-full appearance-none rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 shadow-sm focus:border-blue-500 focus:outline-none focus:ring-blue-500 sm:text-sm"
              />
            </div>
            <div>
              {getRepeatPasswordError()}
            </div>
          </div>
          <div className={"pt-4"}>
            <button
              type="submit"
              className="flex w-full justify-center rounded-md border border-transparent bg-blue-600 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2"
            >
              Update Password
            </button>
          </div>
        </form>
      )
    } else {
      return (
        <div className={"text-lg"}>
          successfully reset password, please <a href={"/signin"} className={"text-blue-600 hover:text-blue-500"}>sign in</a> with your new password
        </div>
      )
    }
  }

  return (
    <div className="flex min-h-full flex-col justify-center py-12 sm:px-6 lg:px-8">
      <div className="sm:mx-auto sm:w-full sm:max-w-md">
        <div className="w-full text-center font-bold text-xl">
          <img
            className="inline-block md:pr-3 mb-2 h-16"
            src={LogoPath}
            alt={"Popstox"}
          />
        </div>
        <h2 className="mt-6 text-center text-3xl font-bold tracking-tight text-gray-900">forgot password</h2>
      </div>

      <div className="mt-8 sm:mx-auto sm:w-full sm:max-w-md">
        <div className="bg-white py-8 px-4 shadow sm:rounded-lg sm:px-10">
          {getForm()}
        </div>
      </div>
    </div>
  )
}

export default ForgotPassword;
